import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { GET, FILE } from 'helpers/api'
import Debtors from './Debtors'

function Index() {
	const { t } = useTranslation()

	const [poses, setPoses] = useState([])
	const [owners, setOwners] = useState([])
	const [data, setData] = useState({
		posId: "",
		login: "",
		file: ""
	})

	// async function importFromExel(e) {
	// 	var formData = new FormData();
	// 	formData.append('file', e.target.files[0])
	// 	formData.append('posId', data.posId)
	// 	formData.append('login', data.login)

	// 	const response = await FILE("services/admin/api/client-in-import", formData, { loader: true })
	// 	if (response) {
	// 		setData({
	// 			posId: "",
	// 			login: "",
	// 			file: ""
	// 		})
	// 		toast.success(t('SUCCESS'))
	// 	}
	// 	e.target.value = ""
	// }

	async function getPoses() {
		const temporaryPoses = await GET('/services/admin/api/pos-helper-login?login=' + data.login)
		if (temporaryPoses) {
			setPoses(temporaryPoses)
		}
	}

	async function getOwners() {
		const response = await GET('/services/uaa/api/owner-helper')
		setOwners(response)
	}

	useEffect(() => {
		getOwners()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps	

	useEffect(() => {
		getPoses()
	}, [data.login]) // eslint-disable-line react-hooks/exhaustive-deps	

	return (
		<div>
			{/* <div className="row">
				<div className="col-md-2">
					<div className="form-group">
						<label>{t('OWNER')}<span className="required-mark">*</span></label>
						<Select
							options={owners}
							value={owners.find(option => option.login === data.login) || ''}
							onChange={(option) => setData({ ...data, 'login': option.login })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.login}
							getOptionValue={(option) => option.id}
						/>
					</div>
				</div>
				<div className="col-md-2">
					<div className="form-group">
						<label>{t('POS')}<span className="required-mark">*</span></label>
						<Select
							options={poses}
							value={poses.find(option => option.id === data.posId) || ''}
							onChange={(option) => setData({ ...data, 'posId': option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
				</div>
				<div className="col-md-3">
					<div className="form-group">
						<label>{t('FILE')}<span className="required-mark">*</span></label>
						<input type="file" className="form-control" onChange={importFromExel} />
					</div>
				</div>
			</div> */}
			<Debtors/>
		</div>
	)
}

export default Index
