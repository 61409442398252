import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/Parts/Search'
import Pagination from 'pages/Parts/Pagination'

import { PGET, POST } from 'helpers/api'
import { formatMoney, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const URL = '/services/admin/api/unconfirmed-payments-pageList'
	const { t } = useTranslation()


	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function confirmPayments(id) {
		console.log('/services/admin/api/confirm-payment/' + id)
		var response = await POST('/services/admin/api/confirm-payment/' + id, {}, { loader: true })

		if (response) {
			getData()
		}
	}

	

	async function getData() {
		const response = await PGET('/services/admin/api/unconfirmed-payments-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))

			setData(response.data);

		}
	}

	async function paginate(data) {
		const response = await PGET('/services/admin/api/unconfirmed-payments-pageList', { page: data.selected, size: settings.size }, { loader: true })
		var updatedData = response.data
		setData(updatedData);
	}

	async function search(data, searchValue) {
		var updatedData = data
		setData(updatedData);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
		
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CONFIRM_PAYMENTS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('SERVER')}</th>
									<th>{t('OWNER')}</th>
									<th className="text-center">{t('CUSTOMER_CREATOR')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('MONTHLY_PAYMENT')}</th>
									<th className="text-center">{t('PAID_FROM')}</th>
									<th className="text-center">{t('PAID_UNTIL')}</th>
									<th className="text-center">{t('ACTIVATE')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'tr-middle '} key={index} >
										<td>{item.id}</td>
										<td>{item.server}</td>
										<td>{item.login} (POS ID: {item.posId})</td>
										<td>{item.createdBy}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="text-center">{formatMoney(item.amountIn)}</td>
										<td className="text-center">{formatMoney(item.monthlyPayment)}</td>
										<td className="text-center">{item.paidFrom}</td>
										<td className="text-center">{item.paidUntil}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.status == 1 ? true : false} disabled={item.status == 1}
														onChange={(e) => confirmPayments(item.id)} />
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

		</>
	)
}

export default Index
